import googleSignInNormal from './google_signin_buttons/web/1x/btn_google_signin_dark_normal_web.png';
import googleSignInFocus from './google_signin_buttons/web/1x/btn_google_signin_dark_focus_web.png';
import googleSignInPressed from './google_signin_buttons/web/1x/btn_google_signin_dark_pressed_web.png';
import googleSignInDisabled from './google_signin_buttons/web/1x/btn_google_signin_dark_disabled_web.png';

export const googleSignIn = {
    normal: googleSignInNormal,
    focus: googleSignInFocus,
    pressed: googleSignInPressed,
    disabled: googleSignInDisabled,
};
