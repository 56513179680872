import { fromUrl, parseDomain, ParseResultType } from 'parse-domain';

export const okDomain = (hostname) => parseDomain(hostname).type === ParseResultType.Listed;

export const okUrl = (url) => url.match(/^https?:\/\//i) && okDomain(fromUrl(url));

export const okActor = (url) => url.match(/^https?:\/\//i) ? okDomain(fromUrl(url)) : okDomain(url);

export const okIp = (ip) => ip.match(/^(?:\d|1?\d\d|2[0-4]\d|25[0-5])\.(?:\d|1?\d\d|2[0-4]\d|25[0-5])\.(?:\d|1?\d\d|2[0-4]\d|25[0-5])\.(?:\d|1?\d\d|2[0-4]\d|25[0-5])$/);

export const okPort = (port) => {
    const p = parseInt(port);
    return port == p && 0 < p && p < 65536;
};

export const okEmail = (email) => {
    const okay = email && email.match(/^[^@\s]+@(?<hostname>(?:[\w-]+\.)+[\w-]+)$/);
    return okay && okDomain(fromUrl(okay.groups.hostname));
};