import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BSON } from "realm-web";
import { RealmContext } from "../contexts/ReactRealmProvider";
import { Link, Navigate, useLocation } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { googleSignIn } from "../assets/googleSignIn";
import { CenteredBox } from "../components/CenteredBox";
import { RealmError } from "./RealmError";
import { okEmail } from "../utils/ok";
import { useEmbedded } from "../hooks/useEmbedded";
import { LanguageContext } from "../contexts/LanguageProvider";

export const Login = () => {
  const language = useContext(LanguageContext);
  const { Realm, app, user, login } = useContext(RealmContext);

  const location = useLocation();
  const params = location.search;
  const query = new URLSearchParams(params);
  const { isEmbedded } = useEmbedded();
  const [loading, setLoading] = useState(false);
  const [google, setGoogle] = useState("normal");
  const [email, setEmail] = useState(query.get("email") || "");
  const [pass, setPass] = useState("");
  const [showPasswordBox, setShowPasswordBox] = useState(false);
  const [error, setError] = useState();

  const refEmail = useRef();
  const refPass = useRef();

  const handleGoogleFailure = (error) => {
    setLoading(false);
    setGoogle("normal");
    console.log({ error });
  };

  const emailIsOk = useMemo(() => okEmail(email), [email]);

  const tryEmailLogin = useCallback(async (email, pass) => {
    setLoading(true);

    login("emailPassword", email, pass, loginFailures)
      .then(() => {
        console.log("success");
        setLoading(false);
      })
      .catch(async (error) => {
        const u = await app.logIn(
          Realm.Credentials.apiKey(import.meta.env.VITE_REALM_API_KEY)
        );
        const mongo = u.mongoClient("mongodb-atlas");

        if (error.errorCode === "InvalidPassword") {
          await mongo
            .db("cart")
            .collection("users")
            .findOneAndUpdate({ email }, { $inc: { loginFails: 1 } });
        }

        await u.logOut();

        setLoading(false);
        setError(error);
      });
  }, []);

  const forgotPassword = useCallback(async (email) => {
    setLoading(true);
    app.emailPasswordAuth
      .callResetPasswordFunction(
        { email, password: new Realm.BSON.ObjectID().toString() },
        true
      )
      .then(() => {
        setError(
          "If this email address has a NetBeacon account, you will receive an email with instructions on how to reset your password. Please check both your inbox and your spam filters."
        );
        setLoading(false);
      })
      .catch(() => {
        setError(
          "If this email address has a NetBeacon account, you will receive an email with instructions on how to reset your password. Please check both your inbox and your spam filters."
        );
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (email) {
      setEmail(email.replace(/ /g, "+"));
      // setShowPasswordBox(true);
    } else {
      refEmail.current.focus();
    }
  }, []);

  useEffect(() => {
    if (showPasswordBox) {
      // refPass.current.focus();
    }
  }, [showPasswordBox]);

  if (user?.isLoggedIn) {
    return isEmbedded || <Navigate to="/" />;
  }

  return (
    <CenteredBox>
      <Backdrop
        open={loading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>

      <Dialog open={Boolean(error)}>
        <DialogTitle>Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {error && <RealmError error={error} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError()}>Okay</Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" flexDirection="column" maxWidth="sm">
        <Typography variant="h3" mb={2}>
          {language.welcome}
        </Typography>
        <Typography mb={1}>{language.positionInternet}</Typography>
        <Typography mb={2}>{language.positionIndustry}</Typography>
        <Typography mb={2}>
          {language.signInBelow} {language.noAccount}{" "}
          <Link to="/signup">{language.signup}</Link>
        </Typography>
        <Typography variant="h5">{language.sso}</Typography>
        <Box display="flex" justifyContent="flex-start">
          <GoogleOAuthProvider clientId="548386455297-vqol1rf0i560kcqh4utie036cq01tek1.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={(response) => {
                const decodedToken = jwtDecode(response.credential);
                login("function", {
                  provider: "google",
                  action: "login",
                  token: decodedToken.id,
                  id: decodedToken.sub,
                  email: decodedToken.email,
                  name: decodedToken.name,
                  last_name: decodedToken.family_name,
                  first_name: decodedToken.given_name,
                }).catch(() => {
                  setLoading(false);
                  setGoogle("normal");
                  setError( `No account exists for this email address. Please sign up first.`);
                });
              }}
              cookiePolicy="single_host_origin"
              onError={handleGoogleFailure}
              render={(renderProps) => (
                <Button
                  disabled={renderProps.disabled}
                  ref={refGoogleButton}
                  onClick={(e) => {
                    if (!renderProps.disabled) {
                      setError("You must consent.");
                      return;
                    } else {
                      setGoogle("disabled");
                      setLoading(true);
                      renderProps.onClick(e);
                    }
                  }}
                >
                  <img
                    tabIndex={0}
                    src={googleSignIn[ google ? google : "disabled"]}
                    alt="Google Sign In"
                    onMouseEnter={() =>
                      setGoogle((g) => (g === "disabled" ? g : "focus"))
                    }
                    onMouseLeave={() =>
                      setGoogle((g) => (g === "disabled" ? g : "normal"))
                    }
                    onMouseDown={() =>
                      setGoogle((g) => (g === "disabled" ? g : "pressed"))
                    }
                  />
                </Button>
              )}
            />
          </GoogleOAuthProvider>
        </Box>
        <Box mt={2} mb={2} display="flex" alignItems="center">
          <Divider sx={{ flexGrow: 1 }} />
          <Typography sx={{ padding: "0.5em" }}>or</Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            inputRef={refEmail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (emailIsOk) setShowPasswordBox(true);
              }
            }}
            label="Email Address"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {!showPasswordBox && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              disabled={!emailIsOk}
              onClick={() => setShowPasswordBox(true)}
            >
              {language.continue}
            </Button>
            <div>
              Don't have an account?{" "}
              <Link to={`/signup${email && `?email=${email}`}`}>
                {language.signUp}
              </Link>
            </div>
          </Box>
        )}
        {emailIsOk && showPasswordBox && (
          <>
            <Box mb={2}>
              <TextField
                fullWidth
                inputRef={refPass}
                type="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    tryEmailLogin(email, pass);
                  }
                }}
                label="Password"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={() => tryEmailLogin(email, pass)}
                >
                  Log In
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  onClick={() => forgotPassword(email)}
                >
                  Forgot Password?
                </Button>
              </Box>
              <Button onClick={() => setShowPasswordBox(false)}>Cancel</Button>
            </Box>
          </>
        )}
        {false && isEmbedded && (
          <>
            <Box mt={2} mb={2} display="flex" alignItems="center">
              <Divider sx={{ flexGrow: 1 }} />
              <Typography sx={{ padding: "0.5em" }}>or</Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {/* <Button variant="contained" onClick={() => login('userApiKey', import.meta.env.VITE_REALM_API_KEY)}>Continue as a Guest</Button> */}
              <Button
                variant="contained"
                onClick={() => {
                  setLoading(true);
                  login("anonymous").then(() => setLoading(false));
                }}
              >
                Continue as a Guest
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CenteredBox>
  );
};

const loginFailures = async (user) => {
  const failures = BSON.EJSON.parse(
    JSON.stringify(user.customData.loginFails || 0)
  );

  if (failures >= 3) {
    throw {
      error: "account locked",
      errorCode: "LockedAccount",
      message: (
        <>
          Your account is locked due to too many failed login attampts. Contact{" "}
          <a href="mailto:info@netbeacon.org">info@netbeacon.org</a> to unlock
          it.
        </>
      ),
    };
  }
};

export default Login;
