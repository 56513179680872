import "./init";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { RealmProvider } from "./contexts/ReactRealmProvider";
import { LanguageProvider } from "./contexts/LanguageProvider";
import { DataProvider } from "./contexts/DataProvider";
import { useEmbedded } from "./hooks/useEmbedded";
import AppRoutes from "./AppRoutes";

function App() {
  const { isEmbedded } = useEmbedded();
  const realmAppId = isEmbedded ? 'embedded-tcsij' : import.meta.env.VITE_REALM_APP_ID;

  // magic to support sav.localhost, etc.
  if (window.location.hostname.match(/\.localhost$/)) {
    return (
      <iframe
        allow="geolocation"
        width={960}
        height={800}
        src={window.location.origin.replace(/[^/]+(?=localhost)/, "")}
      />
    );
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RealmProvider id={realmAppId} remember>
          <DataProvider>
            <LanguageProvider>
              <Routes>
                <Route path="*" index element={<AppRoutes />} />
              </Routes>
            </LanguageProvider>
          </DataProvider>
        </RealmProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000d71",
    },
    secondary: {
      main: "#00A9E0",
    },
    text: {
      primary: "#3e3e3e",
    },
  },
  typography: {
    fontFamily: '"Open Sans"',
  },
});
