export const language = {
    // Sign In and Sign Up
    welcome: {
        en: 'Welcome to NetBeacon',
    },
    error: {
        en: 'Error',
    },
    positionInternet: {
        en: <><b>For Internet users:</b> NetBeacon lets you report online abuse — phishing, spam, botnets, malware, etc. — to the organizations that can act to stop it.</>,
    },
    positionIndustry: {
        en: <><b>For industry partners:</b> NetBeacon reports are vetted and standardized so you can spend less time on abuse admin and more time on action.</>,
    },
    signUp: {
        en: 'Sign Up',
    },
    signup: {
        en: 'Sign up',
    },
    withEmail: {
        en: 'with your email',
    },
    signIn: {
        en: 'Sign In',
    },
    signInBelow: {
        en: 'Sign in to your NetBeacon account below.',
    },
    noAccount: {
        en: 'Don’t have an account?',
    },
    sso: {
        en: "Single sign on",
    },
    signOut: {
        en: 'Sign Out',
    },
    cancel: {
        en: 'Cancel',
    },
    continue: {
        en: 'Continue',
    },
    alreadyHave: {
        en: 'Already have an account?',
    },
    createAccount: {
        en: 'Create your account',
    },
    withGoogle: {
        en: 'with Google',
    },
    iConsentPrivacy: {
        en: <>
            I consent to PIR processing and transfering my personal data as required, 
            as outlined in the <a tabIndex={-1} href="/privacy" target="_blank" rel='nofollow'>Privacy Notice</a>,
            to establish a NetBeacon user account; 
            to accept, forward, and process Reports; 
            and to send me communications I've requested.<br/>
            <small><i>This consent is revocable at any time but may impact the services you are able to access. 
            Please see our <a tabIndex={-1} href="/privacy" target="_blank" rel='nofollow'>Privacy Notice</a> for more information.</i></small>
        </>,
    },
    iConsentTerms: {
        en: <>I have read and accept the <a tabIndex={-1} href="/terms" target="_blank" rel="nofollow">Terms of Use</a>.</>,
    },
    iConsentYouMust: {
        en: <>
            You must click the two checkboxes to signify your consent to the <a tabIndex={-1} href="/privacy" target="_blank" rel='nofollow'>Privacy Notice</a> and the <a tabIndex={-1} href="/terms" target="_blank" rel="nofollow">Terms of Use</a>.
        </>,
    },

    // Incidents
    incidents: {
        en: 'Incidents',
    },
    incidentsText: {
        en: 'Find all submitted abuse reports and filter by type, score, status, reporter, registrar and more.',
    },
    incidentSent: {
        en: 'Sent',
    },
    tooltipIncidentSent: {
        en: 'Was this incident successfully sent to the registrar?',
    },
    incidentScore: {
        en: 'Score',
    },
    tooltipIncidentScore: {
        en: 'Sort by how credible this report seems according to NetBeacon data.',
    },
    incidentStatus: {
        en: 'Status',
    },
    tooltipIncidentStatus: {
        en: 'Sort by open or closed cases',
    },
    incidentType: {
        en: 'Abuse Type',
    },
    tooltipIncidentType: {
        en: 'Sort by accepted abuse types (e.g. phishing, malware, botnet)',
    },
    incidentReported: {
        en: 'Date Reported',
    },
    incidentReporter: {
        en: 'Reported By',
    },
    tooltipIncidentReporter: {
        en: 'Sort alphabetically by email of reporter or reporting body',
    },
    incidentRegistrar: {
        en: 'Registrar',
    },
    tooltipIncidentRegistrar: {
        en: 'Sort alphabetically by registrar through which a domain was registered',
    },
    incidentTLD: {
        en: 'TLD',
    },
    tooltipIncidentTLD: {
        en: 'Sort alphabetically by top level domain (e.g. .org, .com, .io, .xyz)',
    },
    incidentDomain: {
        en: 'Domain',
    },
    tooltipIncidentDomain: {
        en: 'Sort alphabetically by domain name (e.g abc.org > xyz.org)',
    },

    // Report Abuse
    reportAbuse: {
        en: 'Report Online Abuse',
    },
    reportVerb: {
        en: 'Report',
    },
    reportWalk: {
        en: 'We’ll walk you through everything you need to provide a meaningful, actionable online abuse report.',
    },
    reportStart: {
        en: 'To start, please enter the address of the website or page you’re reporting.',
    },
    reportActor: {
        en: 'Suspect site',
    },
    reportActorText: {
        en: 'Share a direct link to the site or page you’re reporting.',
    },
    reportActorHelp: {
        en: 'Web address',
    },
    reportRegistrar: {
        en: 'Domain Registrar',
    },
    reportRegistrarText: {
        en: 'Registered with',
    },
    reportMoreInfo: {
        en: 'More info',
    },
    reportType: {
        en: 'Type of Abuse',
    },
    reportPhishing: {
        en: 'Phishing',
    },
    reportPhishingText: {
        en: 'Phishing attempts to trick people into sharing important personal information—banking information, logins, passwords, credit card numbers. Information gained in phishing attempts can be sold and used to defraud individual victims.',
    },
    reportMalware: {
        en: 'Malware',
    },
    reportMalwareText: {
        en: 'Malware is software that is created to gain access to a user’s computer to steal information or turn the infected device into a drone in a larger botnet. Malware can appear as legitimate software but with changed code users may not notice.',
    },
    reportBotnets: {
        en: 'Botnets',
    },
    reportBotnetsText: {
        en: 'A botnet is the name given to a network of personal computers or other devices that are infected with malicious software without the individual user’s knowledge. Botnets can be used to send spam and in online attacks against websites or institutions.'
    },
    reportSpam: {
        en: 'Spam',
    },
    reportSpamText: {
        en: 'Spam is a volume game and is broadly defined as messages that are sent en masse to as many people as possible. Spam is often used to send inappropriate or irrelevant marketing messages, phishing attempts and other attempts at online abuse.',
    },
    reportSmishing: {
        en: 'Smishing',
    },
    reportSmishingText: {
        en: 'Smishing is a form of phishing that occurs through text messages or instant messages. In this type of scam, attackers seek to deceive recipients into revealing sensitive personal information, such as bank account numbers, passwords, and credit card details. The information obtained through smishing can be sold and used to commit fraud against individual victims.',
    },
    reportNeeded: {
        en: 'What we’ll need…',
    },
    reportRequired: {
        en: 'Required Information',
    },
    reportRequiredText: {
        en: 'Registrars need to see clear evidence of abuse before they’re able to take action. We’ll need:',
    },
    reportHelpful: {
        en: 'Helpful Information',
    },
    reportHelpfulText: {
        en: 'The following will greatly help investigators but are not required.',
    },
};