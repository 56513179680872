import { createContext, useContext, useMemo } from 'react';
import { RealmContext } from './ReactRealmProvider';
import { useLocation } from 'react-router-dom';
import { language } from '../data/language';

export const LanguageContext = createContext({ lang: 'en' });

export const LanguageProvider = ({ children }) => {
    const { customData = {} } = useContext(RealmContext);
    const configLang = customData.lang;

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const queryLang = useMemo(() => query.get('lang'), [query]);

    const get = useMemo(() => {
        const lang = queryLang || configLang || 'en';

        return Object.fromEntries(
            Object.entries(language).map(([ key, langs ]) => [ key, langs[lang] || langs.en ])
        );
    }, [configLang, queryLang]);

    return <LanguageContext.Provider value={get}>{children}</LanguageContext.Provider>
};
