import { createTheme } from '@mui/material/styles';

export const toolbarButtonsTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#000d71',
        },
        secondary: {
          main: '#00A9E0',
        },
        accent: {
            main: '#80c342',
            contrastText: '#fff',
        },  
        text: {
          primary: '#3e3e3e',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginLeft: '1em',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        }
    }
  })