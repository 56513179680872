import React, { useContext, useState } from 'react';
import { RealmContext } from '../contexts/ReactRealmProvider';
import { Link } from 'react-router-dom';
import { Button, Chip, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export const UserToolbar = () => {
    const { customData, logout } = useContext(RealmContext);
    const navigate = useNavigate();
    const { active, approved, type, name, email, reporterOrg, registrarName, registryName } = customData;
    const buttons = [];
    const orgName = { registrar: registrarName, registry: registryName }[type] || reporterOrg;

    const [adminMenu, setAdminMenu] = useState(null);
    const adminMenuOpen = Boolean(adminMenu);
    const openAdminMenu = (event) => setAdminMenu(event.currentTarget);
    const closeAdminMenu = () => setAdminMenu(null);

    if (type) {
        buttons.push(
            <Typography key="user" variant="subtitle2" sx={{ flexGrow: 1 }}>
                <Chip sx={{ marginRight: '0.5em' }} color={approved ? "secondary" : "error"} label={`${type[0].toUpperCase()}${type.substr(1)}`}/>
                {name || email}{(type !== 'admin' && (orgName)) && <> for {orgName}</>}
                {!active && <Chip sx={{ marginLeft: '0.5em' }} color="error" label='Account Deactivated'/>}
                {/* {active && approved && <Verified/>} */}
            </Typography>
        );
    }

    if (active && approved) {        
        if (type === 'admin') {
            buttons.push(
                <React.Fragment key="admin">
                    <Button onClick={openAdminMenu}>Administration {adminMenuOpen ? <ArrowDropUp/> : <ArrowDropDown/>}</Button>
                    <Menu anchorEl={adminMenu} open={adminMenuOpen} onClose={closeAdminMenu}>
                        <MenuItem onClick={() => {
                            navigate('/admin/users');
                            closeAdminMenu();
                        }}>Users</MenuItem>
                        {/* <MenuItem onClick={() => {
                            navigate('/admin/registries');
                            closeAdminMenu();
                        }}>Registries</MenuItem> */}
                        <MenuItem onClick={() => {
                            navigate('/admin/cctlds');
                            closeAdminMenu();
                        }}>ccTLDs</MenuItem>
                        <MenuItem onClick={() => {
                            navigate('/admin/registrars');
                            closeAdminMenu();
                        }}>Registrars</MenuItem>
                        <MenuItem onClick={() => {
                            navigate('/admin/dashboard');
                            closeAdminMenu();
                        }}>Dashboard</MenuItem>
                    </Menu>
                </React.Fragment>
            );
        }

        if (type === 'registry') {
            buttons.push(
                <Button key="dashboard" component={Link} to="/dashboard">Dashboard</Button>,
            );
        }
        if (type === 'admin' || type === 'registry' || type === 'registrar') {
            buttons.push(
                <Button key="incidents" component={Link} to="/incidents">Incidents</Button>,
            );
        }

    }

    if (active) {
        buttons.push(
            <Button key="reports" component={Link} to="/reports">Reports</Button>,
        );
    }

    if (active && type) {
        buttons.push(
            <Button key="submit" component={Link} to="/new-report">Report Abuse</Button>,
            // <Button key="history" component={Link} to="/account/reports">My Reports</Button>,
        );
    }
    
    return [
        ...buttons,
        ...(active ? [<Button key="account" component={Link} to="/account">Settings</Button>] : []),
        <Button key="logout" onClick={() => logout().then(()=>navigate('/login'))}>Log Out</Button>,
    ];
};
