export const RealmError = ({ error }) => {
    // console.dir(error);
    if (! error) return null;
    
    if (error?.error) {
        if (error.error === 'confirmation required') {
            return "Check your inbox for an email from NetBeacon asking you to confirm your email address.";
        }
        else if (error.error === 'name already in use') {
            return "An account already exists for this email address.";
        }
        else if (error.error === 'password must be between 6 and 128 characters') {
            return "Your password must be between 6 and 128 characters.";
        }
        else if (error.error === 'invalid username') {
            return "Your email address is invalid.";
        }
        else if (error.error === 'invalid password' || error.error === 'invalid username/password') {
            return "Your password is incorrect.";
        }
        else if (error.error === 'account locked') {
            return error.message;
        }
        else if (error.error === 'userpass token is expired or invalid') {
            return "This account verification token has expired.";
        }
        else {
            return error.error;
        }
    }

    return error;
};
