import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { RealmContext } from './ReactRealmProvider';
import { Outlet } from 'react-router-dom';
import { SessionTimeoutContext } from './SessionTimeoutContext';

export const SessionTimeoutProvider = ({expiresAfter=60*50, warningAfter=60*40}) => {
  const countdown = useRef();
  const warning = useRef();
  const timeout = useRef();
  const { logout } = useContext(RealmContext);
  const [showWarning, setShowWarning] = useState(false);
  const [expiresIn, setExpiresIn] = useState();

  const resetTimer = useCallback(() => {
    clearTimeout(timeout.current);
    clearTimeout(warning.current);

    setShowWarning(false);
    setExpiresIn(expiresAfter);
    warning.current = setTimeout(
      () => setShowWarning(true),
      1000 * warningAfter
    );
    timeout.current = setTimeout(() => {
      logout();
    }, 1000 * expiresAfter);
  }, [logout]);

  useEffect(() => {
    console.log("timer enabled");

    resetTimer();
    countdown.current = setInterval(() => setExpiresIn((t) => t - 1), 1000);
    window.addEventListener("click", resetTimer);
    window.addEventListener("mousemove", resetTimer);

    return () => {
      clearInterval(countdown.current);
      clearTimeout(timeout.current);
      clearTimeout(warning.current);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("mousemove", resetTimer);
      console.log("timer disabled");
    };
  }, []);

  return (
    <SessionTimeoutContext.Provider value={{}}>
      <Dialog open={showWarning}>
        <DialogTitle>Session Timeout Warning</DialogTitle>
        <DialogContent>
          Your session will expire in {expiresIn} second{expiresIn > 1 && "s"}...
        </DialogContent>
      </Dialog>
         <Outlet />
    </SessionTimeoutContext.Provider>
  );
};
