import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { RealmContext } from '../contexts/ReactRealmProvider';
import { toolbarButtonsTheme } from '../themes/toolbarButtonsTheme';
import cleandns from '../assets/cleandns.png';

export const BottomBar = () => {
    const { customData = emptyObject } = useContext(RealmContext);

    return <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, height: '4rem'}}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <Typography>
                        <Link to="/privacy" style={{ color: 'white' }}>Privacy Notice</Link>
                    </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                    <span style={{marginLeft: '2rem', marginRight: '2rem'}}>Powered by <a href="https://cleandns.com"><img style={{ height: '1rem' }} src={cleandns} /></a></span>
                </Box>
                <Box>
                    <Typography>
                        <Link to="/terms" style={{ color: 'white' }}>Terms of Use</Link>
                        {(customData.type === 'registrar' || customData.type === 'registry') && <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/recipient-terms" style={{ color: 'white' }}>Recipient Terms of Use</Link>
                        </>}
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    </ThemeProvider>;
};