import { useContext } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageProvider';
import {RealmContext} from '../contexts/ReactRealmProvider';
import {toolbarButtonsTheme} from '../themes/toolbarButtonsTheme';
import NetBeacon from '../assets/nb-reporter_horizontal_white.png';
import { UserToolbar } from './UserToolbar';

export const TopBar = () => {
    const language = useContext(LanguageContext);
    const { user, customData = emptyObject } = useContext(RealmContext);
    const loggedIn = user?.isLoggedIn;
    
    return <ThemeProvider theme={toolbarButtonsTheme}>
      <AppBar position='sticky' >
            <Toolbar>
                <Box sx={{ mr: 4, [(user && customData.type) ? 'flexShrink' : 'flexGrow']: 1 }}>
                    <img src={NetBeacon} height={30} />
                </Box>
                {<>
                    {loggedIn && <UserToolbar />}
                    {!loggedIn && <Button component={Link} to="/signup">{language.signUp}</Button>}
                    {!loggedIn && <Button component={Link} to="/login">{language.signIn}</Button>}
                </>}
            </Toolbar>
        </AppBar>
    </ThemeProvider>;
};